<template>
  <div class="container" :style="height">
    <div class="title">售后政策</div>
    <div class="shouhou">
      <p>* 在课程开启前，可在APP上选择退课，购买课程的费用会在3-5个工作日原路返回。</p>
      <p>* 若课程在35天内没有开启，视为自动退课，购买课程的费用也会自动原路退回。</p>
      <p>* 课程一旦开启，将不再能够退课，购课款也无法退回。</p>
    </div>
  </div>
</template>
<script>
import { Salelist } from "@/assets/js/api";
export default {
  name: "buynow",
  data() {
    return {
      height: { height: "" },
      salecont: ""
    };
  },
  methods: {
    // 可视区域高度
    getHight() {
      this.height.height = window.innerHeight - 180 + "px";
    },
    Salelist() {
      Salelist({}).then(result => {
        if (result.code == 0) {
          this.salecont = result.appSysAfterSale.text;
        }
      });
    }
  },
  created() {
    this.getHight();
    window.addEventListener("resize", this.getHight);
    this.Salelist();
  },
  destroyed() {
    window.removeEventListener("resize", this.getHight);
  },
  mounted() {}
};
</script>
<style lang="scss" scoped>
.shouhou {
  p {
    margin-bottom: 10px;
  }
}
</style>